import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box } from '@material-ui/core'

export const Wrapper = styled(Box)`
  width: 400px;
  margin: 20px 26px 20px 0;
  display: inline-block;
  & > img {
    width: 400px;
    height: 267px;
  }
  & .MuiContainer-root {
    display: flex;
    justify-content: center;
  }
  & .MuiContainer-root img {
    width: 80%;
  }
`

export const SlideLink = styled(Link)`
  border: 2px solid;
  padding: 15px;
  border-radius: 12px;
`

export const CourseBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  align-items: center;

  & > a {
    color: rgb(41, 41, 41);
    font-size: 20px;
    text-align: center;
  }

  & > div {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }

  & div:nth-child(3) {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul {
    width: 100%;
    margin-top: 25px;
  }

  li {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 5px 0;
  }

  span {
    margin-left: 7px;
    font-size: 16px;
  }
`

export const SubscribeBtn = styled.a`
  font-size: 19px;
  border: 2px solid #15752f;
  padding: 12px;
  border-radius: 5px;
  background: #1c7735;
  color: #fff;

  &:hover {
    color: #fff;
  }
`
export const ReadBtn = styled.a`
  color: rgba(212, 20, 20, 0.87);
  font-weight: 600;
`
