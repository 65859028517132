import React from 'react'
import { Link } from 'gatsby'
import { AttachMoney, CalendarToday, Bookmark } from '@material-ui/icons'
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import StreetviewIcon from '@material-ui/icons/Streetview';
import SlideOverlay from '../SlideOverlay'
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { Paper, Grid, Container, Typography } from '@material-ui/core';
import { Wrapper, SlideLink, CourseBody, SubscribeBtn, ReadBtn } from './styles'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
  boxPaper: {
    padding: '15px',
  },
  paperStyle: {
    padding: '20px'
  },
  linkTitle: {
    color: '#ef4e45',
    fontWeight: 'bold',
    '&:focus':{
      color: '#ef4e45',
    }
  }
}))
const PoloWrapper = props => {
  const classes = useStyles()
  return (
    <>


      <Grid item xs className={classes.boxPaper}>
        <Paper className={classes.paperStyle} elevation={3}>
          <Link to={props.link}><Typography className={classes.linkTitle}>{props.name}</Typography></Link>
          <Grid>
           
            <span>
              <b>Cidade:</b> {props.city}
            </span>
          </Grid>
          <Grid>
            
            <span>
              <b>Bairro:</b>{props.district}
            </span>
          </Grid>
          <Grid>
           
            <span>
              <b>Estado:</b> {props.state}
            </span>
          </Grid>
        </Paper>

      </Grid>


      {/* <Wrapper> */}
      {/* <SlideOverlay image={props.img} alt={`Imagem do curso ${props.name}`}>
      </SlideOverlay> */}
      {/* <CourseBody>
        <Link to={props.link}>{props.name}</Link>
        <ul>
          <li>
            <LocationCityIcon />
            <span>
              <b>Cidade:</b> {props.city}
            </span>
          </li>
          <li>
            <LocationSearchingIcon />
            <span>
              <b>Bairro:</b>{props.district}
            </span>
          </li>
          <li>
            <StreetviewIcon />
            <span>
              <b>Endereço:</b> {props.address}
            </span>
          </li>
        </ul>
        <div>
         
          <ReadBtn href={props.link} target="_blank">
            Saiba mais
          </ReadBtn>
        </div>
      </CourseBody> */}

      {/* </Wrapper> */}
    </>
  )
}

export default PoloWrapper
