import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Container, Grid, Input } from '@material-ui/core'

import {
  CollectionsBookmark,
  ColorLens,
  Public,
  Business,
  Eco,
  Computer,
  LocalHospital,
  Build,
  Work,
} from '@material-ui/icons'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import PoloWrapper from '../../components/PoloWrapper'

import { PageTitle } from '../../components/PageTitle'
import {
  SectionTitle,
  SearchBar,
} from '../../styles/pages/presencial'

export default function SergipePage(props) {
  const { data } = props
  const { edges } = data.allMarkdownRemark

  const [filteredCourses, setFilteredCourses] = useState(edges)
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    const posts = filteredCourses.filter(({ node }) =>
      node.frontmatter.name.toLowerCase().includes(query.toLowerCase())
    )
    setSearchResults(posts)
  }, [query.toLowerCase()])


  return (
    <Layout>
      <SEO title="Graduação EaD" />

      <Container style={{ marginTop: 70 }}>
        <PageTitle>Polos Sergipe</PageTitle>

        <p
          style={{
            marginTop: 50,
            fontSize: 18,
            color: '#333',
            textAlign: 'justify',
            lineHeight: '1.8em',
            wordSpacing: 8,
          }}
        >
          Na modalidade a distância, as atividades virtuais ficam a todo tempo
          disponíveis aos alunos mediante acesso ao AVA* – Ambiente Virtual de
          Aprendizagem da Faculdade Jardins. As atividades presenciais previstas
          ocorrem em encontros previamente marcados a cada semestre, para
          realização, inclusive, da avaliação presencial. Contemplando assim, a
          tutoria para ensino, extensão, iniciação à pesquisa e atividades
          supervisionadas, visando à formação diferenciada de nossos alunos.
          <br /> <br />
          <span style={{ fontSize: 15, color: '#555' }}>
            *AVA – Ambiente Virtual de Aprendizagem da Faculdade Jardins é uma
            Plataforma de Aprendizagem on-line completa para otimizar a formação
            de nossos alunos, dispondo, inclusive, de vídeos aula, e-books,
            biblioteca virtual, periódicos virtuais, materiais de aula dos
            docentes e tutores, slides, artigos, textos, hipertextos, links,
            chats, fóruns, exercícios, avaliações, etc..
          </span>
        </p>
      </Container>
      <Container disableGutters={true} style={{ marginTop: 60 }}>
        <SectionTitle>Polos</SectionTitle>

        <SearchBar>
          <Input
            placeholder="Pesquisar polo"
            value={query}
            onChange={e => setQuery(e.target.value)}
            style={{ padding: 6, borderRadius: 5, border: '2px solid' }}
          />
        </SearchBar>

        <div>
          {searchResults.map(({ node }) => (
            <PoloWrapper
              city={node.frontmatter.city}

              address={node.frontmatter.address}
              district={node.frontmatter.district}
              name={node.frontmatter.name}
              link={node.fields.slug}
            />
          ))}
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: {state: {eq: "SE" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            state
            district
            city
            address
            category
           
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
